<template>
  <div>
    <vs-card>
      <div slot="header" class="items-center flex justify-end">
          <strong
            v-if="
              ($store.state.AppActiveUser.userType === 'admin' ||
                $store.state.AppActiveUser.userType === 'superAdmin') &&
              basicUserData.profileUpdated
            "
            style="display: block"
            >Profile update request. Review to approve / reject</strong
          >
          <div class="lg:hidden flex items-center space-x-2 w-full">
            <vs-button
              v-if="
                ($store.state.AppActiveUser.userType === 'admin' ||
                  $store.state.AppActiveUser.userType === 'superAdmin') &&
                basicUserData.profileUpdated
              "
              @click="showProfileApprovalView">Review</vs-button
            >

            <vs-button @click="getAccountLogs" class="bg-green-light text-primary hover:text-primary" type="filled"> View Logs </vs-button>
            <vs-button @click="saveData" class="flex-1">Save Details</vs-button>
            <vs-button
              v-if="check_has_permission('changeNursePassword')"
              @click="resetPasswordHandler"
              class="flex-1 truncate"  
              >Change Password</vs-button
            >
          </div>
          <div class="hidden lg:block">
            <div class="flex justify-items-end justify-end">
              <div>
                <div
                  v-if="
                    ($store.state.AppActiveUser.userType === 'admin' ||
                      $store.state.AppActiveUser.userType === 'superAdmin') &&
                    basicUserData.profileUpdated
                  "
                >
                  <vs-button @click="showProfileApprovalView" class="m-2"
                    >Review</vs-button
                  >
                </div>
              </div>

              <div class="flex items-center justify-end space-x-3 w-full">
                <vs-button @click="getAccountLogs" class="bg-green-light text-primary hover:text-primary" type="filled"> View Logs </vs-button>
                <vs-button @click="saveData">Save Details</vs-button>
                <vs-button
                  v-if="check_has_permission('changeNursePassword')"
                  @click="resetPasswordHandler"
                  >Change Password</vs-button
                >
              </div>
            </div>
          </div>
      </div>

      <form>
        <div class="flex flex-wrap">

          <div
            id="profileImageUpload"
            class="vs-con-loading__container w-full lg:w-2/12 flex flex-col items-center lg:items-start mb-6 mt-2"
          >
            <div class="flex items-center">
              <div
                style="cursor: pointer"
                @click="open_in_new_tab(basicUserData.profileImage)"
                class="img-wrapper"
                v-if="basicUserData.profileImage"
              >
                <img :src="basicUserData.profileImage" class="img-fluid" alt />
              </div>
              <vs-avatar
                style="cursor: pointer"
                @click="open_in_new_tab(defaultPP)"
                size="110px"
                :src="defaultPP"
                v-else
              />
            </div>
            <label
              class="image-upload block text-primary font-medium text-center text-base mt-2">
              Change Photo
              <input
                type="file"
                accept="image/*"
                @change="uploadImage($event, 'profileImageUpload')"
                id="file-input"
                hidden
              />
            </label>
          </div>

          <div class="w-full grid grid-cols lg:grid-cols-2 gap-4">
              <div class="w-full">
                <vs-input
                  :success="
                    !errors.first('firstName') && basicUserData.firstName != ''
                  "
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="errors.first('firstName') ? true : false"
                  :danger-text="errors.first('firstName')"
                  v-validate="'required|max:150'"
                  name="firstName"
                  data-vv-as="first name"
                  label="First Name"
                  icon-no-border
                  icon="icon icon-user"
                  icon-pack="feather"
                  class="w-full"
                  v-model="basicUserData.firstName"
                />
              </div>
           
              <div class="w-full">
                <vs-input
                  :success="
                    !errors.first('lastName') && basicUserData.lastName != ''
                  "
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="errors.first('lastName') ? true : false"
                  v-validate="'required|max:150'"
                  name="lastName"
                  data-vv-as="last name"
                  label="Last Name"
                  class="w-full"
                  :danger-text="errors.first('lastName')"
                  icon-no-border
                  icon="icon icon-user"
                  icon-pack="feather"
                  v-model="basicUserData.lastName"
                />
              </div>
           
              <div class="w-full">
                <vs-input
                  :success="
                    !errors.first('contactNumber') &&
                    basicUserData.contactNumber != ''
                  "
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="errors.first('contactNumber') ? true : false"
                  v-validate="'required'"
                  name="contactNumber"
                  :danger-text="errors.first('contactNumber')"
                  data-vv-as="contact number"
                  label="Contact Number"
                  class="w-full"
                  icon-no-border
                  icon="icon icon-phone"
                  icon-pack="feather"
                  v-model="basicUserData.contactNumber"
                />
              </div>
           
              <div class="w-full">
                <label>Date Of Birth</label>
                <datepicker
                  format="dd/MM/yyyy"
                  v-model="basicUserData.dateOfBirth"
                  :input-class="{
                    'is-true':
                      !errors.has('dateOfBirth') && basicUserData.dateOfBirth,
                    'is-danger': errors.has('dateOfBirth'),
                  }"
                >
                  <template slot="afterDateInput">
                    <span
                      v-if="basicUserData.dateOfBirth"
                      class="
                        bg-color-done
                        input-icon-validate
                        vs-input--icon-validate
                      "
                    >
                      <i
                        valiconpack="material-icons"
                        class="
                          vs-icon
                          notranslate
                          icon-scale
                          material-icons
                          null
                        "
                        >done</i
                      >
                    </span>
                    <span
                      v-if="errors.has('dateOfBirth')"
                      class="
                        bg-color-error
                        input-icon-validate
                        vs-input--icon-validate
                      "
                    >
                      <i
                        valiconpack="material-icons"
                        class="
                          vs-icon
                          notranslate
                          icon-scale
                          material-icons
                          null
                        "
                        >error</i
                      >
                    </span>
                  </template>
                </datepicker>
                <span class="text-danger" style="font-size: 0.75em">
                  {{ errors.first("dateOfBirth") }}
                </span>
                <input
                  type="hidden"
                  data-vv-as="Date of birth"
                  data-vv-validate-on="change"
                  name="dateOfBirth"
                  v-validate="{ required: isRequired }"
                  v-model="basicUserData.dateOfBirth"
                />
              </div>
           
              <div class="w-full">
                <!--<label for>Email</label>
                <p style="margin-right: 5px; font-size: 1rem">
                  {{ basicUserData.email }}
                </p>-->
                <vs-row vs-type="flex" vs-align="left" class="w-full">
                  <vs-input
                    :success="
                      !errors.first('email') && basicUserData.email != ''
                    "
                    val-icon-success="done"
                    val-icon-danger="error"
                    :danger="errors.first('email') ? true : false"
                    v-validate="'required|email'"
                    name="email"
                    data-vv-as="email"
                    label="Email"
                    class="w-full"
                    :danger-text="errors.first('email')"
                    v-model="basicUserData.email"
                  />
                </vs-row>
                <vs-row vs-type="flex" vs-align="left" class="w-full">
                  <vx-tooltip
                    v-if="basicUserData.emailVerifiedAt"
                    text="Verified"
                    style="display: flex"
                  >
                    <feather-icon
                      style="color: rgba(var(--vs-primary), 1) !important"
                      icon="CheckCircleIcon"
                      svgClasses="h-5 w-5"
                      class="mr-2 cursor-pointer"
                    ></feather-icon>
                  </vx-tooltip>
                  <vx-tooltip v-else text="Unverified" style="display: flex">
                    <feather-icon
                      style="color: rgba(var(--vs-danger), 1) !important"
                      icon="AlertCircleIcon"
                      svgClasses="h-5 w-5"
                      class="mr-2 cursor-pointer"
                    ></feather-icon>
                  </vx-tooltip>
                  <VerifyEmail
                    @onSuccessfullyVerified="onSuccessfullyVerified"
                    v-if="!basicUserData.emailVerifiedAt"
                    :userId="basicUserId"
                  />
                </vs-row>
              </div>

              <div v-if="basicUserData.isSupplier" class="flex w-full lg:w-1/2">
                <div class="w-full">
                  <label class="label" name="treatment-type">Brand</label>
                  <vs-select
                    placeholder="Select brand"
                    autocomplete
                    multiple
                    name="brand"
                    class="w-full"
                    v-model="selectedBrands"
                    >
                    <vs-select-item
                      :key="index"
                      :value="item._id"
                      :text="item.brandName"
                      v-for="(item, index) in brands"
                      :clearable="false"
                    />
                  </vs-select>
                  <div
                    class="
                      con-text-validation
                      span-text-validation-danger
                      vs-input--text-validation-span
                      v-enter-to
                    "
                    style="height: 19px"
                  >
                    <span v-if="!selectedBrands.length" class="span-text-validation">
                      {{ errors.first("brand") }}
                    </span>
                  </div>
                </div>
            </div>

            <div class="flex flex-col lg:flex-row items-start lg:items-center justify-start space-y-4 lg:space-y-0 w-full">
              <div class="flex items-center justify-center space-x-1 pr-6">
                <label for>Supplier</label>
                <vs-switch
                  :disabled="organizations.length === 0"
                  v-model="basicUserData.isSupplier"
                />
              </div>
              <div class="flex items-center justify-center space-x-1 pr-6">
                <label for>Disable User</label>
                <vs-switch
                  color="danger"
                  @click="changeStatus(userStatus)"
                  v-model="userStatus"
                />
                <div
                  v-if="statusChangedClick"
                  style="width: 20px; height: auto; display: flex"
                >
                  <span style="margin-right: 5px">Processing</span>

                  <img
                    src="../../../../public/loader.gif"
                    style="width: 100%; height: 100%"
                  />
                </div>
              </div>
              <div v-if="adminApprovalVar == true" class="flex items-center justify-center space-x-1">
                <label for>Admin Approval</label>
                <vs-switch
                  @click="changeAdminApproval(adminStatus)"
                  v-model="adminStatus"
                />
                <div
                  v-if="adminApprovalChangeClick"
                  style="width: 20px; height: auto; display: flex"
                >
                  <span style="margin-right: 5px">Processing</span>
                  <img
                    src="../../../../public/loader.gif"
                    style="width: 100%; height: 100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

      </form>
    </vs-card>

    <vs-popup title="Reset Password" :active.sync="resetPassword">
      <form autocomplete="off">
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('resetPassword.newPassword') && password != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="
                  errors.first('resetPassword.newPassword') ? true : false
                "
                name="newPassword"
                :danger-text="errors.first('resetPassword.newPassword')"
                label="New Password"
                class="w-full"
                v-validate="{
                  required: true,
                  min: 8,
                  regex:
                    /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9_.*\W]+$)/,
                }"
                data-vv-as="new password"
                data-vv-scope="resetPassword"
                ref="password"
                icon-no-border
                icon-pack="feather"
                v-model="password"
                :icon="passwordIcon"
                :type="passwordType"
                @click.native="showHidePassword($event)"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                label="Confirm Password"
                :success="
                  !errors.first('resetPassword.confirmPassword') &&
                  confirmPassword != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="
                  errors.first('resetPassword.confirmPassword') ? true : false
                "
                name="confirmPassword"
                :danger-text="errors.first('resetPassword.confirmPassword')"
                v-validate="'required|confirmed:password'"
                data-vv-as="confirm password"
                data-vv-scope="resetPassword"
                class="w-full"
                icon-no-border
                icon-pack="feather"
                v-model="confirmPassword"
                :icon="confirmPasswordIcon"
                :type="confirmPasswordType"
                @click.native="showHideConfirmPassword($event)"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-button
                size="lg"
                @click="changePassword"
                icon-pack="feather"
                class="mr-2"
                >Reset Password</vs-button
              >
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5" align="right">
              <vs-button
                @click="resetPassword = false"
                color="danger"
                icon-pack="feather"
                class="mr-2"
                >Cancel</vs-button
              >
            </div>
          </vs-col>
        </vs-row>
      </form>
    </vs-popup>

    <LogsSidebar :activity_logs="activityLogs" ref="logsSidebar" />
  </div>
</template>

<script>
import axios from "@/axios";
import { mapActions } from "vuex";
import Datepicker from "vuejs-datepicker";
import { Validator } from "vee-validate";
import VerifyEmail from "@/views/general-component/VerifyEmail";
import moment from "moment";
import { updatedFieldChecker } from "../../../utils/updatedFieldChecker";
import LogsSidebar from "@/views/components/account-creation-logs/logs.vue";

const dict = {
  custom: {
    firstName: {
      required: "Please enter your first name",
    },
    lastName: {
      required: "Please enter your last name",
    },
    email: {
      required: "Please enter valid email address",
    },
    contactNumber: {
      required: "Please enter your contact number",
    },
    ahpraRegistrationNumber: {
      required: "Please enter valid AHPRA registration number",
    },
    ahpraExiryDate: {
      required: "Please enter valid AHPRA Expiry Date",
    },
    indemnityInsuranceProvider: {
      required: "Please enter valid indemnity insurance name",
    },
    indemnityInsuranceNumber: {
      required: "Please enter valid indemnity insurance number",
    },
    indemnityInsuranceExpiry: {
      required: "Please enter valid indemnity insurance expiry date",
    },
    newPassword: {
      required: "Please enter your password",
      min: "Password must be at least 8 characters",
      regex: "Must have at least one number and  one uppercase letter",
    },
    confirmPassword: {
      required: "Please enter your confirm password",
      confirmed: " The confirmation password do not match",
    },
  },
};
Validator.localize("en", dict);
export default {
  name: "basicUser-detail",
  components: {
    Datepicker,
    VerifyEmail,
    LogsSidebar
  },
  props: {
    showTreatmentHistory: {
      type: Boolean,
      default: true,
    },
    fetchClinicsBy: {
      type: String,
      default: "franchise",
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    adminApprovalVar: {
      type: Boolean,
      default: false,
    },
    backRoute: {
      type: String,
      default: "/franchise/nurses",
    },
  },
  data: () => ({
    isRequired: true,
    activeUserType: "",
    defaultPP: require("@/assets/images/profile/default.png"),
    statusChangedClick: false,
    adminApprovalChangeClick: false,
    resetPasswordPopup: false,
    basicUserId: "",
    password: "",
    confirmPassword: "",
    validationKeys: {
      showRestrictionImposeError: false,
      showAggreementOnAhpraRegistrationChangedError: false,
      showAggreementOnIndemnityRegistrationChangedError: false,
      showRnOrEnError: false,
      showToxinTreatmentError: false,
      showFillerTreatmentError: false,
      showHasCompletedHyalaseTraningInLastTwelveMonth: false,
      showHasCompletedBasicLifeSupportTrainingInLastTwelveMonth: false,
      showHasReadClinicalStandardsDocumentsError: false,
      showHasConfirmedDeclarationsError: false,
      showHasImmunityToHepatitisBError: false,
      showCanSendEmailError: false,
    },
    organizations:[],
    basicUserData: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      contactNumber: null,
      profileImage: "",
      signature: "",
      status: false,
      isSupplier: false,
      hasApprovedByAdmin: "",
      userType: "basic",
      emailVerifiedAt: null,
      photoId: null,
      dateOfBirth: null,
      brands: [],
    },
    clinics: [],
    brands: null,
    selectedBrands: [],
    clinic: "",
    disabledDates: { to: new Date() },
    userStatus: false,
    adminStatus: false,
    resetPassword: false,
    passwordType: "password",
    passwordIcon: "icon icon-eye-off",
    confirmPasswordType: "password",
    confirmPasswordIcon: "icon icon-eye-off",
    updater: null,
    oldData: null,
    active: false,
    activityLogs: []
  }),
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.basicUserData.firstName != "" &&
        this.basicUserData.lastName != "" &&
        this.basicUserData.contactNumber != "" &&
        this.basicUserData.email != "" &&
        this.basicUserData.dateOfBirth != ""
      );
    },
  },
  methods: {
    ...mapActions("basicUser", [
      "fetchBasicUserDetails",
      "updateBasicUserDetails",
      "resetBasicUserPassword",
      "fetchBasicUserOrganizations",
    ]),
    ...mapActions("franchise", ["fetchClinics"]),
    ...mapActions("product", ["fetchBrands"]),
    ...mapActions("appUser", ["getAccountCreationLogs"]),
    getBrands() {
      this.fetchBrands().then(res=>{
        this.brands = res;
      });
    },
    showHidePassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.passwordType === "password"
          ? (this.passwordType = "text")
          : (this.passwordType = "password");
        this.passwordIcon === "icon icon-eye-off"
          ? (this.passwordIcon = "icon icon-eye")
          : (this.passwordIcon = "icon icon-eye-off");
      }
      return true;
    },
    showHideConfirmPassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.confirmPasswordType === "password"
          ? (this.confirmPasswordType = "text")
          : (this.confirmPasswordType = "password");
        this.confirmPasswordIcon === "icon icon-eye-off"
          ? (this.confirmPasswordIcon = "icon icon-eye")
          : (this.confirmPasswordIcon = "icon icon-eye-off");
      }
      return true;
    },
    async saveData() {
      let isValid = await this.$validator.validateAll(null);

      if (
        moment(new Date(this.basicUserData.dateOfBirth)).format("YYYY-MM-DD") >
        moment().subtract(18, "years").format("YYYY-MM-DD")
      ) {
        isValid = false;
        this.errors.add({
          field: "dateOfBirth",
          msg: "Your age must be 18 years or above",
        });
      } else {
        this.errors.remove("dateOfBirth");
      }

      if (this.basicUserData.isSupplier && !this.selectedBrands.length){
        isValid = false;
        this.errors.add({
          field: "brand",
          msg: "This field is required",
        });
      } else {
        this.errors.remove("brand");
        this.basicUserData["brands"] = this.selectedBrands;
      }

      if (isValid) {
        if (this.adminStatus == true)
          this.basicUserData.hasApprovedByAdmin = true;
        if (this.adminStatus == false)
          this.basicUserData.hasApprovedByAdmin = false;
        if (this.userStatus == false) this.basicUserData.status = "Active";
        if (this.userStatus == true) {
          this.basicUserData.status = "Disabled";
          // this.basicUserData.hasApprovedByAdmin = false;
        }
        //this.basicUserData.hasApprovedByAdmin = this.adminStatus
        this.$vs.loading();
        this.basicUserData.updater = `${this.updater.firstName} ${this.updater.lastName}`,
        this.basicUserData.userId = this.basicUserData.id,
        this.basicUserData.updatedFields = updatedFieldChecker(this.oldData, this.basicUserData)

        try {
          await this.updateBasicUserDetails({
            id: this.basicUserId,
            data: this.basicUserData,
          });

          this.$vs.notify({
            title: "Basic user updated",
            text: "Basic user's details updated successfully.",
            color: "success",
          });
          const user = this.$store.state.AppActiveUser.userRole;
          this.$vs.loading.close();
          await this.getBasicUserIdDetail(this.basicUserId);
        } catch (err) {
          this.$vs.notify({
            title: "Failed",
            text: "Failed to update Basic user. Try again!",
            color: "danger",
          });
          isValid = false;
          if (err.status === 422) {
            this.mapServerErrors(this, err, "");
          }
          if (err.status === 417) {
            this.errors.add({
              field: "email",
              msg: err.data.message,
            });
          }
          this.$vs.loading.close();
        }
      }
    },
    chang(key = "") {
      this.$vs.loading({
        text: "Uploading ...",
        color: '"#3dc9b3"',
        background: "white",
        container: "#" + key + " div div",
      });
    },
    handleError(key = "") {
      this.$vs.loading.close("#" + key + " div div > .con-vs-loading");
      this.$vs.notify({
        title: "Upload Failed",
        text: "Unable to upload file at the moment.",
        color: "danger",
      });
    },
    determineFileType(str) {
      if (str && str.length > 0) {
        var res = str.substring(str.length - 3, str.length);
        return res;
      }
    },
    onSuccessfullyVerified(verifiedAt) {
      this.basicUserData.emailVerifiedAt = verifiedAt;
    },
    successUpload(tab, event) {
      let response = JSON.parse(event.target.response);
      if (tab == "a") {
        this.$vs.loading.close("#aphraUpload div div > .con-vs-loading");
        this.basicUserData.ahpraCertificate = response.Location;
        this.errors.remove("aphraCertificate");
      }
      if (tab == "i") {
        this.$vs.loading.close("#insuranceUpload div div > .con-vs-loading");
        this.basicUserData.insuranceCertificate = response.Location;
        this.errors.remove("insuranceCertificate");
      }

      if (tab == "pp") {
        this.basicUserData.profileImage = response.Location;
        this.$vs.loading.close("#profileUpload div div > .con-vs-loading");
      }

      this.$vs.loading.close();
    },
    deleteFile(tab) {
      if (tab == "a") this.basicUserData.ahpraCertificate = "";
      if (tab == "i") this.basicUserData.insuranceCertificate = "";
      if (tab == "s") this.basicUserData.signature = "";
      if (tab == "pp") this.basicUserData.profileImage = "";
    },
    async changePassword() {
      const isValid = await this.$validator.validateAll("resetPassword");
      if (isValid) {
        try {
          await this.resetBasicUserPassword({
            id: this.basicUserId,
            data: {
              password: this.password,
              confirmPassword: this.confirmPassword,
            },
          });

          this.resetPassword = false;
          this.resetPasswordPopup = false;
          this.password = "";
          this.confirmPassword = "";
          this.$validator.reset();
          this.$vs.notify({
            title: "Basic user's password reset",
            text: "Basic user's password reset Successfully.",
            color: "success",
          });
        } catch (err) {
          this.$vs.notify({
            title: err.data.title,
            text: err.data.message,
            color: "danger",
          });
        }
      }
    },
    mapServerErrors(self, err, form = "") {
      let data = err.data.data.details;
      data.map(function (value, key) {
        self.errors.add({
          field: value.context.key,
          msg: value.message.replace(/"/g, ""),
          scope: form,
        });
      });
    },
    async getBasicUserIdDetail(basicUserId) {
      const res = await this.fetchBasicUserDetails(basicUserId);
      if (res.data.data.permissions) {
        this.$store.commit("UPDATE_USER_PERMISSION", res.data.data.permissions);
      }
      const basicUser = res.data.data;
      this.basicUserId = basicUser._id;
      this.basicUserData = {
        id: basicUser._id,
        firstName: basicUser.firstName,
        lastName: basicUser.lastName,
        contactNumber: basicUser.contactNumber,
        profileImage: basicUser.profileImage,
        isSupplier: basicUser.isSupplier,
        status: basicUser.status,
        hasApprovedByAdmin: basicUser.hasApprovedByAdmin,
        profileUpdated: basicUser.profileUpdated,
        email: basicUser.email,
        emailVerifiedAt: basicUser.emailVerifiedAt,
        clinicName: basicUser.clinicName,
        photoId: basicUser.photoId,
        dateOfBirth: basicUser.dateOfBirth,
        brands: basicUser.brands
      };
      if (basicUser.brands.length){
        this.selectedBrands = basicUser.brands;
      }
      if (this.basicUserData.status == "Active") this.userStatus = false;
      else this.userStatus = true;

      this.adminStatus = this.basicUserData.hasApprovedByAdmin;
      this.oldData = basicUser;
      this.oldData.contactNumber = basicUser.contactNumber;
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },

    uploadImage(event, key = "profileImage") {
      this.$vs.loading({
        text: "Uploading...",
        color: "#3dc9b3",
        background: "white",
        container: "#" + key,
      });
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload", data, config)
        .then((res) => {
          if (key === "profileImageUpload") {
            this.basicUserData.profileImage = res.data.Location;
          }
          if (key === "aphraUpload") {
            this.basicUserData.ahpraCertificate = res.data.Location;
          }
          if (key === "insuranceUpload") {
            this.basicUserData.insuranceCertificate = res.data.Location;
          }
          if (key === "signature") {
            this.basicUserData.signature = res.data.Location;
          }
          if (key === "photoIdUpload") {
            this.errors.remove("photoId");
            document.getElementById("photoId").style.borderColor = "#cccccc";
            this.basicUserData.photoId = res.data.Location;
          }
          if (key === "trainingCertificateUpload") {
            this.errors.remove("trainingCertificate");
            document.getElementById("trainingCertificate").style.borderColor =
              "#cccccc";
            this.basicUserData.trainingCertificate = res.data.Location;
          }
          if (key === "cvUpload") {
            this.errors.remove("cv");
            document.getElementById("cvId").style.borderColor = "#cccccc";
            this.basicUserData.cv = res.data.Location;
          }
          if (key === "hyalaseManagenentCertificateUpload") {
            this.errors.remove("hyalaseOrComplicationManagementCertificate");
            document.getElementById(
              "hyalaseManagenentCertificateId"
            ).style.borderColor = "#cccccc";
            this.basicUserData.hyalaseOrComplicationManagementCertificate =
              res.data.Location;
          }
          if (key === "blsCertificateUpload") {
            this.errors.remove("blsCertificate");
            document.getElementById("blsCertificateId").style.borderColor =
              "#cccccc";
            this.basicUserData.blsCertificate = res.data.Location;
          }
          if (key === "vaccinationCertificateUpload") {
            this.errors.remove("vaccinationCertificate");
            document.getElementById(
              "vaccinationCertificateId"
            ).style.borderColor = "#cccccc";
            this.basicUserData.vaccinationCertificate = res.data.Location;
          }
          this.$vs.loading.close("#" + key + " > .con-vs-loading");
        })
        .catch((err) => {
          this.$vs.loading.close("#" + key + " > .con-vs-loading");
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              color: "danger",
            });
          }
        });
    },
    resetPasswordHandler() {
      this.resetPassword = true;
      this.resetPasswordPopup = true;
      // document.getElementById("resetPasswordContainer").scrollIntoView();
    },
    showProfileApprovalView() {
      let loggedInUserType =
        this.$store.state.AppActiveUser.userType === "superAdmin"
          ? "super-admin"
          : "admin";
      this.$router.push(
        `/${loggedInUserType}/nurses/${this.$route.params.basicUserId}/profile-update-review`
      );
    },
    place_changed(a) {},
    async changeStatus(status) {
      this.statusChangedClick = true;
      let us = "Active";
      if (!status) us = "Disabled";

      let updateData = {
        status: us,
        updater: this.updater.firstName + " " + this.updater.lastName,
        userId: this.basicUserData.id,
        updatedFields: {
          status: {
            oldValue: us ==='Disabled' ? 'Active' : 'Disabled', newValue: us
          }
        }
      };

      // if (us === "Active" && !this.basicUserData.emailVerifiedAt) {
      //   updateData.emailVerifiedAt = new Date().toISOString();
      // }
      try {
        const res = await this.updateBasicUserDetails({
          id: this.basicUserId,
          data: updateData,
        });

        // this.basicUserData.emailVerifiedAt = res.data.data.emailVerifiedAt;
        this.$vs.notify({
          title: "Success",
          text: "Status updated successfully.",
          color: "success",
        });
        this.statusChangedClick = false;
      } catch (err) {
        this.$vs.notify({
          title: "Failed",
          text: "Failed to update status. Try again!",
          color: "danger",
        });
        this.statusChangedClick = false;
      }
    },
    async changeAdminApproval(status) {
      this.adminApprovalChangeClick = true;

      let updateData = {
        hasApprovedByAdmin: !status,
        updater: this.updater.firstName + " " + this.updater.lastName,
        userId: this.basicUserData.id,
        updatedFields: {
          hasApprovedByAdmin: {
            oldValue: status, newValue: !status
          }
        }
      }

      try {
        await this.updateBasicUserDetails({
            id: this.basicUserId,
            data: updateData,
          });
        this.$vs.notify({
          title: "Success",
          text: "Admin approval updated successfully.",
          color: "success",
        });
        this.adminApprovalChangeClick = false;
      } catch (err) {
        this.$vs.notify({
          title: "Failed",
          text: "Failed to update admin approval. Try again!",
          color: "danger",
        });
        this.adminApprovalChangeClick = false;
      }
    },
    async getAccountLogs() {
      this.$refs.logsSidebar.openSidebar();

      await this.getAccountCreationLogs({
        id: this.$route.params.basicUserId, 
        type: 'user',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      })
        .then(res => {
         this.activityLogs = res

        }).catch(err => {
          console.warn(err)
        })
      }
  },
  async created() {
    this.updater = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_PROCESS_TOKEN}User`)
    );

    this.activeUserType = this.$store.state.AppActiveUser.userType;
    let basicUserId = this.$route.params.basicUserId;
    this.basicUserId = basicUserId;
    await this.getBasicUserIdDetail(basicUserId);
    const organizations = await this.fetchBasicUserOrganizations(basicUserId);
    this.organizations = organizations.data.data;
    this.getBrands();
    const res = await this.fetchClinics({ type: this.fetchClinicsBy });
    if (this.fetchClinicsBy === "franchise") {
      this.clinics = res.data.data;
    } else {
      this.clinic = res.data.data.clinicId;
    }

    this.isRequired =
      this.$store.state.AppActiveUser.userType === "superAdmin" ||
      this.$store.state.AppActiveUser.userType === "admin" ||
      this.$store.state.AppActiveUser.userType === "franchise" ||
      this.$store.state.AppActiveUser.userType === "subFranchise"
        ? false
        : true;
  },
  mounted() {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    this.disabledDates.to = d;
  },
};
</script>
<style lang="scss">
.disabled-upload {
  display: none;
}
.con-img-upload {
  height: 50%;
}
.is-danger {
  border: 1px solid #ea5455 !important;
}
.is-true {
  border: 1px solid #28c76f !important;
}
.bg-color-done {
  background: #d4f4e2;
  color: #28c76f !important;
  border: 1px solid #28c76f !important;
  border-left: hidden !important;
}

.bg-color-error {
  background: #fbdddd;
  color: #ea5455 !important;
  border: 1px solid #ea5455 !important;
  border-left: hidden !important;
}

.sidebarx {
  .vs-sidebar {
    max-width: 400px;

    &--items {
      overflow-y: auto;
      overflow-x: hidden !important;
    }
  }
}
</style>
